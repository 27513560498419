.guild-card {
  width: 415px;
  height: 220px;
  padding: 10px;
  justify-content: center;

  // Add hover effect for guild name
  &:hover .guild-name {
    color: #dd0031;
  }
}

.card-content {
  text-align: center;
  width: 100%;
}

.guild-name {
  margin-bottom: 10px;
}

.guild-desc {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Number of lines to show */
  -webkit-box-orient: vertical; 
}

