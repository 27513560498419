.logo {
  justify-content: center;
  position: relative;
  color: #dd0031;
  top: 210px;
  width: 100%;
}
.test-text {
  height: 25rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #004f71;
  top: 100px;
  width: 100%;
  font-weight: bold;
  font-size: 2rem;
}
.custom-button {
  margin-top: 20px;
  position: relative;
  top: -75px;
  left: 50%;
  transform: translateX(-50%);
}
