/* Colors */

$primary-btn-light: #f03961;
$primary-btn-dark: #c00f2f;
$primary: #dd0031;
$primary-dark: #af272f;
$default: #5b6770;
$white-gray: #e5e5e5;
$dark-gray: #3c3c3c;
$light-gray: #8e969c;
$disabled-gray: #eaeaea;
$nav-border: #f0eded;
$gray-border: $nav-border;
$navy: #004f71;
$teal: #3eb1c8;
$teal-outline: #bee7ee;
$soft-teal: #aee1ea;
$white-gray: #f5f5f5;
$faint-gray: #cccccc;
$secondary-green: #00635b;
$secondary-purple: #994878;
$white: #ffffff;
$success: #249e6b;
$spicy-honey-mustard: #d18d2a;
$honey-mustard: #ffb549;
$paste: #f5e3cc;
$barbecue: #6e3b3b;

$gray-background1: #ecedee;

// App variables

.danger {
  color: $primary-dark;
}

.warning {
  color: $honey-mustard;
}

.info {
  color: $navy;
}
