.test-text {
  height: 30.625rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #dd0031;
  top: 72px;
}
