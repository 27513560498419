@import '../../../../App.scss';

.pod-card {
  font-family: 'Apercu';
  font-weight: 400;
  border: 20px;
  //cursor: pointer;
  transition: transform 0.5s ease;
}

.pod-collapsed {
  height: 0px;
  transition: 0.25s ease;
  overflow: hidden;
}

.name-arrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.pod-name:hover {
  color: #dd0031;
}

.pod-uncollapsed {
  /* Define your collapsed styles here */
  height: fit-content; /* Example height */
  // max-height: 700px;
  overflow: scroll;
  transition: max-height 0.5s ease;
  position: relative;
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden;
}

.card-header {
  background-color: $white;
  color: $navy;
  padding: 16px;
}

.podCardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 34px 16px 8px;
}
