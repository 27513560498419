@import '../../../App.scss';

.main-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 86px - 72px);
  width: 100%;
  position: relative;
  top: 80px;
  padding-top: 20px;
  padding-bottom: 100px;
}

.title-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
}

.body-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 60px;
  margin-right: 60px;
}

.infoPencil :hover {
  color: lightgray;
}

.bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-right: 40px;
}

.top-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 25px;
  margin-right: 25px;
}

.create-guild-button {
  position: fixed;
  bottom: 25px;
  right: 40px;
}

.guilds .saveAndCloseButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
  z-index: 1000;
}

.delete-guild-button {
  position: fixed;
  bottom: 25px;
  right: 40px;
}
