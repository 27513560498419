.search-bar {
  display: flex;
  flex: 1 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid grey;
}

.search-container {
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
}

.search-result-container {
  display: flex;
  flex-direction: row;
}

.view-all-label {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.search-result-header {
  display: flex;
  flex-direction: row;
  margin-right: 80px;
  margin-left: 80px;
  justify-content: space-between;
  margin-bottom: -10px;
}
