@import '../../../App.scss';

.homeMainContainer {
  min-height: calc(100vh - 86px - 72px);
}

.explorePods {
  height: 23.5rem;
  background-color: #fad5cf;
  margin-top: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.buttonsEdit {
  display: flex;
  gap: 20px;
}

.explorePods h2 {
  width: auto;
  height: 2rem;
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: 1.125rem;
  color: #004f71;
  text-align: center;
  padding: 4rem 2rem;
}

.home-hero-container {
  height: 30.625rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 72px;
}

h1.home-welcome-title {
  font-family: Apercu-bold;
  font-size: 3rem;
  line-height: 3.5rem;
  text-align: center;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin: 0;
  height: auto;
  color: #004f71;
}
