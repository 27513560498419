@import '../../../App.scss';

.NavbarItems {
  background-color: $white;
  display: flex;
  align-content: center;
  flex-direction: row;
  width: 100%;
  position: fixed;
  z-index: 30;
  height: 4.5rem;
  padding: 0.75rem 3rem;
  left: 0;
  top: 0;
  box-shadow: 0px 4px 8px rgba(91, 103, 112, 0.24);
  cursor: pointer !important;
}

.start-section {
  width: 75%;
  display: flex;
}

.cfa-logo {
  height: 3rem;
  width: auto;
  margin-right: 1.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  height: auto;
  margin: 0;
  padding: 0;
  z-index: 30;
}

.nav-links {
  text-decoration: none;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $default;
  font-family: 'Apercu-bold';
  margin: 0;
  padding: 0;
  margin-right: 1rem;
}

.nav-links:hover {
  text-decoration: none;
  color: $primary;
}

.nav-links.active {
  text-decoration: none;
  color: $primary;
}

.nav-links:focus {
  text-decoration: none;
  color: $primary-dark;
}

.search_account {
  width: 25%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin-left: 1rem;
  z-index: 30;
  gap: 8px;
}

.navSearchIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  padding: 0;
  margin-right: 1.5625rem;
}

.icon:hover {
  color: $primary !important;
}

.searchModalResults {
  margin-top: 20px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.searchModalResults li {
  display: inline-block;
  width: 250px;
  vertical-align: top;
  margin: 0 5px 10px 5px;
}
.closeIcon {
  display: none;
}

@media screen and (max-width: 800px) {
  .search_account {
    visibility: hidden;
  }
}
