@import '../../../App.scss';

.main-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 86px - 72px);
  width: 100%;
  position: relative;
  top: 80px;
  padding-top: 20px;
  padding-bottom: 100px;
}

.title-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
}

.create-lt-button {
  position: fixed;
  bottom: 25px;
  right: 40px;
  z-index: 1;
}

.saveAndCloseButtons {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.infoPencil :hover {
  color: lightgray;
}

.delete-leadership-button {
  position: fixed;
  bottom: 25px;
  right: 40px;
}
