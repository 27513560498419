.pointsofcontact-dept-name:hover {
  color: #dd0031;
}

.card-collapsed {
  max-height: 0px;
  overflow: hidden;
}

.card-uncollapsed {
  display: contents;
  padding-top: 30px;
}
