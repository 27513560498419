@import '../../../App.scss';

.main_container {
  min-height: calc(100vh - 86px - 72px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 72px;
}

.nav_section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0;
  margin-bottom: 24px;
  padding-top: 6px;
}

.main_card {
  height: 632px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  border: 1px solid #cccfd0;
}

.role_header {
  padding: 22px 30px 30px 30px;
  color: #b6072f;
}

.roles_list {
  margin: 10px 20px;
  overflow-y: scroll;
}

.create_btn {
  color: #b6072f;
  padding: 12px 24px;
  gap: 8px;
}

.edit_icon {
  width: 16px;
  height: 16px;
}

.role_list_items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.subdept_list_items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.leadership_list_items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

// .subnavbar {
//     margin-left: 100px;
// }
