.points-of-contact:hover {
  color: var(--primary-base, #dd0031);
}

.points-of-contact:hover + .divider {
  border-color: var(--primary-base, #dd0031);
}

.points-of-contact {
  cursor: pointer;
}
