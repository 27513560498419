@import '../../../App.scss';

.selected-tab {
  position: relative;
}

.selected-tab::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 97%;
  height: 3px;
  background-color: rgb(244, 36, 36);
  z-index: 1;
}

.selected-tab .tab-text {
  color: rgb(228, 10, 10);
}

.selected-tab.active .tab-text {
  color: red;
}

.main-container {
  min-height: calc(100vh - 86px - 72px);
  width: 100%;
  position: relative;
  top: 80px;
  padding-bottom: 100px;
}

.deptNavBar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  position: fixed;
  z-index: 20;
  background-color: $white;
  padding-bottom: 2px;
}

.title_section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  position: relative;
  top: 78px;
}

h2.dept {
  font-family: 'Apercu';
  font-weight: bolder;
  font-size: 32px;
  color: $primary;
  padding-top: 40px;
  margin: 0;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
}

.divideLine {
  margin-top: 40px;
  width: 70%;
  height: 1px;
  background-color: #cccfd0;
  border-radius: 4px;
}

section {
  position: relative;
  top: 78px;
}

section .results-list {
  width: 100%;
  padding: 30px;
  margin-top: 30px;
  columns: 20rem;
  column-gap: 1rem;
  counter-reset: grid;
  list-style-type: none;
}

.results-list li {
  width: 250px;
  min-width: 258px;
  max-width: 258px;
  margin: auto;
}

.subdepartments-list {
  color: black;
}

.create-pod-button {
  position: fixed;
  bottom: 25px;
  right: 40px;
}

.loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40%;
  width: 100%;
  flex-direction: column;
}
