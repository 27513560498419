@import '../../../App.scss';

.footer {
  background-color: $white;
  height: 5.375rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  top: 72px;
}

p.footer-info {
  width: 100%;
  font-size: 1rem;
  color: $primary;
  line-height: 1.5rem;
  margin: 2rem;
  font-family: 'Apercu-bold';
  text-align: center;
}

@media screen and (max-width: 400px) {
  p.footer-info {
    margin: 1rem;
  }
}
