.main-page {
  padding-top: 90px;
  padding-left: 50px;
  padding-right: 50px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
